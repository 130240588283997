import React from 'react'

const ReliableToolsHeading = () => {
    return (
        <div className='w-screen bg-black text-white lg:pt-16 pb-8 pt-20'>
            <div className='w-[95%] lg:w-[85%] mx-auto '>
                <h1 className='lg:text-3xl font-bold text-2xl'>Reliable Techs & Tools We Are Proficient In</h1>
                <p className='text-sm lg:mt-5 mt-2 font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas ultrices scelerisque urna sed man proin lacinia. Posuere facilisis ut nullam ipsum at enim. Ut imperdiet eu sodales eros. nibh elementum eget. Integer amet, consectetur adipiscing elit. </p>
            </div>
        </div>
    )
}

export default ReliableToolsHeading